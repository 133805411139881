<template>
  <div class="flex w-full inline-flex justify-between font-poppins">
    <div>
      <h3 class="text-2xl md:text-3xl leading-6 text-gray-900">
        My Profile
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Personal details.
      </p>
    </div>
    <div>
      <button @click="logout" class="bg-gray-800 h-12 rounded-xl text-gray-50 hover:bg-gray-100 hover:text-gray-900 px-4 hover:border-gray-900 hover:border">Log out</button>
    </div>
  </div>
  <div
      class="
        mt-5
        sm:mt-6
        sm:grid
        sm:grid-cols-2
        sm:gap-3
        sm:grid-flow-row-dense
        text-red-700
"
      v-if="errors.length"
  >
    <p v-for="error in errors" v-bind:key="error">
      {{ error }}
    </p>
  </div>
  <div class="flex flex-col md:flex-row mt-6 justify-around border border-gray-100 rounded shadow-md">
    <div>
      <form class="text-left p-4 md:w-72" @submit.prevent="updateUser()">
        <p class="font-poppins text-left text-lg text-gray-500">
          Personal information
        </p>
        <div class="mt-6">
          <label
              for="username"
              class="block text-sm font-medium text-gray-500"
          >
            Username
          </label>

          <div class="mt-1 h-12">
              <input
                  disabled
                  id="username"
                  name="username"
                  type="text"
                  autocomplete="username"
                  v-model="me.username"
                  class="
                  bg-gray-200
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
              />
          </div>
        </div>
        <div class="mt-6">
          <label
              for="email"
              class="block text-sm font-medium text-gray-500"
          >
            Email
          </label>

          <div class="mt-1 h-12">
            <input
                disabled
                id="email"
                name="email"
                type="text"
                autocomplete="email"
                v-model="me.mail"
                class="
                  bg-gray-200
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <div class="mt-6">
          <label
              for="first_name"
              class="block text-sm font-medium text-gray-500"
          >
            First Name
          </label>

          <div class="mt-1 h-12">
            <input
                required
                id="first_name"
                name="first_name"
                type="text"
                autocomplete="first_name"
                v-model="me.first_name"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <div class="mt-6">
          <label
              for="last_name"
              class="block text-sm font-medium text-gray-500"
          >
            Last Name
          </label>

          <div class="mt-1 h-12">
            <input
                required
                id="last_name"
                name="last_name"
                type="text"
                autocomplete="last_name"
                v-model="me.last_name"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <u-i-button
            class="flex-1 my-2"
            kind="save"
            as="button"
            type="submit"
        >Save</u-i-button>
      </form>
    </div>
    <div>
      <form class="text-left p-4 md:w-72" @submit.prevent="updatePassword()">
        <p class="font-poppins text-left text-lg text-gray-500">
          Change your password
        </p>
        <div class="mt-6">
          <label
              for="old_password"
              class="block text-sm font-medium text-gray-500"
          >
            Old password
          </label>

          <div class="mt-1 h-12">
            <input
                required
                id="old_password"
                name="old_password"
                type="password"
                autocomplete="old_password"
                v-model="old_password"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <div class="mt-6">
          <label
              for="new_password"
              class="block text-sm font-medium text-gray-500"
          >
            New password
          </label>

          <div class="mt-1 h-12">
            <input
                required
                id="new_password"
                name="new_password"
                type="password"
                autocomplete="new_password"
                v-model="new_password"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <div class="mt-6">
          <label
              for="confirm_password"
              class="block text-sm font-medium text-gray-500"
          >
            Confirm password
          </label>

          <div class="mt-1 h-12">
            <input
                required
                id="confirm_password"
                name="confirm_password"
                type="password"
                autocomplete="confirm_password"
                v-model="confirm_password"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-atol-blue-electric
                  focus:border-atol-blue-electric
                  sm:text-sm
                "
            />
          </div>
        </div>
        <u-i-button
            class="flex-1 my-2"
            kind="save"
            as="button"
            type="submit"
        >Change Password</u-i-button>
      </form>
    </div>
    </div>
</template>

<script>
import axios from "axios";
import UIButton from "@/components/UIButton";

export default {
  name: "Profile",
  components: {UIButton},
  mounted() {
    document.title = "My Profile | Durban"
    this.get_user()
  },
  data() {
    return {
      me: '',
      errors: [],
      old_password: '',
      new_password: '',
      confirm_password: ''
    }
  },
  methods: {
    logout() {
      axios.defaults.headers.common['Authorization'] = ''

      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem('userid')
      this.$store.commit('removeToken')
      this.$store.commit('logout')

      this.$router.push('/')
    },
    async get_user() {
      await axios.get("/api/v1/me/")
          .then(response => {
            this.me = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
    async updateUser() {
      await axios.post("/api/v1/me/", {user: JSON.stringify(this.me)})
          .then(response => {
            this.me = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
    async updatePassword() {
      let data = {
        new_password: this.new_password,
        re_new_password: this.confirm_password,
        current_password: this.old_password
      }
      await axios.post("/api/V1/users/set_password/", data)
          .then(response => {
            this.me = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    }
  }
}
</script>

<style scoped>

</style>